
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import helper from "@/utils/helper";
const mobile = ref(false);
export default defineComponent({
  name: "business",
  setup() {
    const dataSource = ref({});
    const router = useRouter();

    onBeforeMount(() => {
      if (helper.is_mobile()) {
        mobile.value = true;
      }
      const url = process.env.NODE_ENV == "production" ? "/data/business.json": "/data/business.json";
      axios.get(url).then((res: any) => {
        if (res.status == 200) {
          dataSource.value = res.data;
        }
      });
    });

    const details = (val:{uid:string})=>{
      router.push({name:'businessDetails',query:{id:val.uid}})
    }

    return {
      dataSource,
      mobile,
      details
    };
  },
});

import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4234b9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "business" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "bg" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.mobile?'title title2':'title'])
      }, null, 2),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource.m_list, (item, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item",
            onClick: ($event: any) => (_ctx.details(item)),
            key: idx,
            style: _normalizeStyle({marginTop:idx>2?'30px':''})
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['img','img'+(idx+1)])
            }, [
              _withDirectives(_createElementVNode("img", {
                src: require('../../assets/image/business/cai'+(item.id)+'.png'),
                alt: ""
              }, null, 8, _hoisted_5), [
                [_vShow, _ctx.mobile]
              ])
            ], 2),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("img", {
                src: require('../../assets/image/business/bg'+(item.m_bg)+'.png'),
                alt: ""
              }, null, 8, _hoisted_7), [
                [_vShow, _ctx.mobile]
              ]),
              _withDirectives(_createElementVNode("img", {
                src: require('../../assets/image/business/bg'+(item.bg)+'.png'),
                alt: ""
              }, null, 8, _hoisted_8), [
                [_vShow, !_ctx.mobile]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(item.desc), 1)
          ], 12, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}